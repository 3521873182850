import React from "react";
import Layout from "../../components/Layout";

export default function multimedia() {
    return (
        <Layout>
            <div id="main">
                <div className="services_section_banner">
                    <div
                        className="services_banner"
                        style={{backgroundImage: `url(../../images/sector_educativo/8.jpg)`}}
                    >
                        <h1>Multimedia</h1>
                    </div>
                </div>
                <div className="inner">
                    <div className="services_section_1">
                        <h1 className="services_section_1__title">Descripción</h1>
                        <p className="services_section_1__paragraph">
                            Acceso a colecciones videográficas para todas las áreas del conocimiento, principalmente
                            cine documental y video educativo, publicado por casas productoras reconocidas a nivel
                            internacional. Nuestro amplio portafolio incluye plataformas streaming con acceso a
                            discografía selecta: música clásica, conte4mporánea y jazz, así como animaciones
                            interactivas para reforzamiento del aprendizaje a nivel básico.
                            Consulte detalles de este portafolio de soluciones pulsando los siguientes enlaces:
                        </p>
                    </div>
                </div>
            </div>
        </Layout>
    )
}